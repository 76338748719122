angular.module(config.app.name).component('cobuyers', {
    templateUrl: 'components/procedure/components/cobuyers.component.html',
    bindings: {
        canGoBack: '<',
        coBuyers: '<',
        setData: '&'
    },
    controller: function ($element) {
        var self = this;

        self.view = {
            title: 'Cotitulaires',
            icon: 'group',
            buttons: {
                isCoBuyersPage: true
            }
        };

        self.currentCoBuyer = 0;

        self.$onInit = function () {
            if (!self.coBuyers || self.coBuyers.length === 0) {
                self.coBuyers = [
                    {}
                ];
            }
        };

        self.addCoBuyer = function (title, view) {
            self.coBuyers.push({});
        };
        self.removeCoBuyer = function () {
            if (self.currentCoBuyer > -1 && self.coBuyers.length > 1) {
                if (confirm('Voulez-vous vraiment supprimer le cotitulaire N° ' + (self.currentCoBuyer + 1) + ' ?')) {
                    self.coBuyers.splice(self.currentCoBuyer, 1);
                }
            }
        };

        self.saveCoBuyer = function (index, cobuyer) {

            console.log('>>> save cobuyer :', index, cobuyer)
            cobuyer.firstCoBuyer = index === 0;

            Object.assign(self.coBuyers[index], cobuyer);
            self.setData({
                type: 'coBuyer',
                data: {coBuyers: self.coBuyers}
            });
        };

        self.hasValidData = function () {
            return self.coBuyers.some((coBuyer) => 'firstname' in coBuyer || 'company' in coBuyer);
        };

        self.next = function (cancel = false) {
            self.setData({
                type: 'coBuyers',
                data: {coBuyers: cancel === false ? {} : null}
            });
        };

        self.back = function () {
            $element[0].dispatchEvent(new CustomEvent('navigation', {
                detail: 'back'
            }));
        };

    }
});